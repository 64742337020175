var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-container"},[_c('div',{staticClass:"timeline"},[_c('div',{staticClass:"timeline-container approved"},[_c('div',{staticClass:"timeline-icon"},[_c('b-icon-check',{attrs:{"scale":"1.5"}})],1),_c('div',{staticClass:"timeline-body"},[_c('h4',{staticClass:"timeline-title"},[_vm._v("Inicio de la solicitud")]),_c('p',{staticClass:"timeline-subtitle"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.data.createdAt))+" ")])])]),_c('div',{staticClass:"timeline-container",class:_vm.data.status === 0 ? 'actual' : 'approved'},[_c('div',{staticClass:"timeline-icon"},[(_vm.data.status === 0)?_c('b-icon-arrow-right',{attrs:{"scale":"1.5"}}):_c('b-icon-check',{attrs:{"scale":"1.5"}})],1),_c('div',{staticClass:"timeline-body"},[_c('h4',{staticClass:"timeline-title"},[_vm._v("Criterios")]),_vm._l((_vm.data.criterias),function(criteria,i){return _c('ul',{key:i},[_c('li',[_vm._v(_vm._s(criteria.criteriaName))])])}),_vm._l((_vm.data.justificationCriterias),function(criteria,h){return _c('ul',{key:h},[_c('li',[_vm._v(_vm._s(criteria.criteriaName))])])}),(_vm.data.justificationCriterias.length)?_c('h4',{staticClass:"timeline-title"},[_vm._v(" Justificación ")]):_vm._e(),_vm._l((_vm.data.justificationCriterias),function(criteria,k){return _c('ul',{key:`${k}-k`},[_c('li',[_vm._v(_vm._s(criteria.justificationCriteriaName))])])})],2)]),(
        (_vm.data.status !== 0 && _vm.data.criterias.find((x) => x.status === 1)) ||
        _vm.data.justificationCriterias.find((x) => x.status === 1)
      )?_c('div',{staticClass:"timeline-container approved"},[_c('div',{staticClass:"timeline-icon"},[_c('b-icon-check',{attrs:{"scale":"1"}})],1),_c('div',{staticClass:"timeline-body"},[_c('h4',{staticClass:"timeline-title"},[_vm._v("Aprobados")]),_vm._l((_vm.data.criterias),function(criteria,l){return _c('ul',{key:l},[(criteria.status === 1)?_c('li',[_vm._v(_vm._s(criteria.criteriaName))]):_vm._e()])}),_vm._l((_vm.data.justificationCriterias),function(criteria,m){return _c('ul',{key:m},[(criteria.status === 1)?_c('li',[_vm._v(" "+_vm._s(criteria.justificationCriteriaName)+" ")]):_vm._e()])}),_c('p',{staticClass:"timeline-subtitle"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.data.lastModifiedAt))+" ")])],2)]):_vm._e(),(
        (_vm.data.status !== 0 && _vm.data.criterias.find((x) => x.status === 2)) ||
        _vm.data.justificationCriterias.find((x) => x.status === 2)
      )?_c('div',{staticClass:"timeline-container approved"},[_c('div',{staticClass:"timeline-icon"},[_c('b-icon-check',{attrs:{"scale":"1"}})],1),_c('div',{staticClass:"timeline-body"},[_c('h4',{staticClass:"timeline-title"},[_vm._v("Rechazados")]),_vm._l((_vm.data.criterias),function(criteria,c){return _c('ul',{key:c},[(criteria.status === 2)?_c('li',[_vm._v(_vm._s(criteria.criteriaName))]):_vm._e()])}),_vm._l((_vm.data.justificationCriterias),function(criteria,j){return _c('ul',{key:j},[(criteria.status === 2)?_c('li',[_vm._v(" "+_vm._s(criteria.justificationCriteriaName)+" ")]):_vm._e()])}),_c('p',{staticClass:"timeline-subtitle"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.data.lastModifiedAt))+" ")])],2)]):_vm._e(),_c('div',{staticClass:"timeline-container",class:_vm.data.status === 0 ? 'pending' : 'approved'},[_c('div',{staticClass:"timeline-icon"},[_c('b-icon-flag',{attrs:{"scale":"1"}})],1),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-body"},[_c('h4',{staticClass:"timeline-title text-center"},[_vm._v("Completado")])])
}]

export { render, staticRenderFns }