<template>
  <div class="timeline-container">
    <div class="timeline">
      <div class="timeline-container approved">
        <div class="timeline-icon">
          <b-icon-check scale="1.5" />
        </div>
        <div class="timeline-body">
          <h4 class="timeline-title">Inicio de la solicitud</h4>
          <p class="timeline-subtitle">
            {{ formatDate(data.createdAt) }}
          </p>
        </div>
      </div>
      <div
        class="timeline-container"
        :class="data.status === 0 ? 'actual' : 'approved'"
      >
        <div class="timeline-icon">
          <b-icon-arrow-right scale="1.5" v-if="data.status === 0" />
          <b-icon-check scale="1.5" v-else />
        </div>
        <div class="timeline-body">
          <h4 class="timeline-title">Criterios</h4>
          <ul v-for="(criteria, i) in data.criterias" :key="i">
            <li>{{ criteria.criteriaName }}</li>
          </ul>
          <ul v-for="(criteria, h) in data.justificationCriterias" :key="h">
            <li>{{ criteria.criteriaName }}</li>
          </ul>
          <h4 class="timeline-title" v-if="data.justificationCriterias.length">
            Justificación
          </h4>
          <ul v-for="(criteria, k) in data.justificationCriterias" :key="`${k}-k`">
            <li>{{ criteria.justificationCriteriaName }}</li>
          </ul>
        </div>
      </div>
      <div
        class="timeline-container approved"
        v-if="
          (data.status !== 0 && data.criterias.find((x) => x.status === 1)) ||
          data.justificationCriterias.find((x) => x.status === 1)
        "
      >
        <div class="timeline-icon">
          <b-icon-check scale="1" />
        </div>
        <div class="timeline-body">
          <h4 class="timeline-title">Aprobados</h4>
          <ul v-for="(criteria, l) in data.criterias" :key="l">
            <li v-if="criteria.status === 1">{{ criteria.criteriaName }}</li>
          </ul>
          <ul v-for="(criteria, m) in data.justificationCriterias" :key="m">
            <li v-if="criteria.status === 1">
              {{ criteria.justificationCriteriaName }}
            </li>
          </ul>
          <p class="timeline-subtitle">
            {{ formatDate(data.lastModifiedAt) }}
          </p>
        </div>
      </div>
      <div
        class="timeline-container approved"
        v-if="
          (data.status !== 0 && data.criterias.find((x) => x.status === 2)) ||
          data.justificationCriterias.find((x) => x.status === 2)
        "
      >
        <div class="timeline-icon">
          <b-icon-check scale="1" />
        </div>
        <div class="timeline-body">
          <h4 class="timeline-title">Rechazados</h4>
          <ul v-for="(criteria, c) in data.criterias" :key="c">
            <li v-if="criteria.status === 2">{{ criteria.criteriaName }}</li>
          </ul>
          <ul v-for="(criteria, j) in data.justificationCriterias" :key="j">
            <li v-if="criteria.status === 2">
              {{ criteria.justificationCriteriaName }}
            </li>
          </ul>
          <p class="timeline-subtitle">
            {{ formatDate(data.lastModifiedAt) }}
          </p>
        </div>
      </div>
      <div
        class="timeline-container"
        :class="data.status === 0 ? 'pending' : 'approved'"
      >
        <div class="timeline-icon">
          <b-icon-flag scale="1" />
        </div>
        <div class="timeline-body">
          <h4 class="timeline-title text-center">Completado</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "review-request-timeline",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
    },
  },
};
</script>

<style lang="scss" scoped>
// colors
$actual: orange;
$approved: #00bf9a;
$rejected: #e21a1a;

// timeline variables
$timeline-before-bg: #222a42;
$timeline-body-bg: #27293d;
$timeline-item-inactive: gray;
$timeline-body-round: 3px;
$timeline-body-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

body {
  font-family: "K2D", sans-serif;
  letter-spacing: 1px;
}

// page container
.timeline-container {
  max-width: 800px;
  margin: 30px auto;
}

// typography
p {
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
  opacity: 0.8;
}

// timeline style
.timeline {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 30px;
  color: white;

  &:before {
    content: "";
    position: absolute;
    left: -1px;
    top: 1rem;
    width: 4px;
    height: calc(100% - 2.5rem);
    background: $timeline-body-bg;
  }

  .timeline-container {
    position: relative;
    margin-bottom: 2.5rem;

    .timeline-icon {
      position: absolute;
      left: -88px;
      top: 4px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      font-size: 2rem;

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .timeline-body {
      background: $timeline-body-bg;
      border-radius: $timeline-body-round;
      padding: 20px 20px 15px;
      box-shadow: $timeline-body-shadow;

      &:before {
        content: "";
        background: inherit;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        left: -10px;
        transform: rotate(45deg);
        border-radius: 0 0 0 2px;
      }

      .timeline-title {
        margin-bottom: 0.5rem;

        .badge {
          padding: 4px 8px;
          border-radius: 3px;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .timeline-subtitle {
        font-weight: 300;
        font-style: italic;
        opacity: 0.4;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 11px;
      }
    }

    // Actual Step Timeline
    &.actual {
      .badge,
      .timeline-icon {
        background: $actual !important;
      }
    }

    // Approved Timeline
    &.approved {
      .badge,
      .timeline-icon {
        background: $approved !important;
      }
    }

    // Dnager Timeline
    &.rejected {
      .badge,
      .timeline-icon {
        background: $rejected !important;
      }
    }

    &.pending {
      .badge,
      .timeline-icon {
        background: $timeline-item-inactive !important;
      }

      .timeline-body {
        opacity: 0.5;
      }
    }
  }
}
</style>
