<template>
    <b-row class="mb-2">
        <b-col sm="4">
            <label class="details-title">{{ label }}</label>
        </b-col>
        <b-col>
            <span class="details-title-value">{{ value }}</span>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "law-firm-property",
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: "-",
        },
    },
};
</script>