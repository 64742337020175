<template>
    <div class="mt-5 px-xl-5">
        <h6 class="page-title pb-2 text-uppercase">
            <b-button class="main-btn rounded me-2" @click="goToLawFirms">
                <b-icon icon="arrow-left"></b-icon
            ></b-button>
            {{ $t("law-firm.title") }}
        </h6>
        <b-tabs fill nav-class="dash-nav-tab">
            <b-tab :title="$t('law-firm-info.title')" active title-item-class="dash-tab">
                <LawFirmInfo :id="id" @law-firm-info="setLawFirm" />
                <LawFirmAgents :lawFirmId="id" />
            </b-tab>
            <b-tab :title="$t('law-firm-request-activation-inactivation.title')" title-item-class="dash-tab" v-if="lawFirm != null">
                <ActivationInactivation :lawFirm="lawFirm" @refresh-request-history="setReload" />
                <RequestActivationInactivation :lawFirmId="id" :reload="reload" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import LawFirmInfo from "./LawFirmInfo.vue";
import LawFirmAgents from "./LawFirmAgents.vue";
import ActivationInactivation from "../cur-management/ActivationInactivation.vue";
import RequestActivationInactivation from "../cur-management/RequestActivationInactivation.vue";

export default {
    name: "law-firm",
    components: {
        LawFirmInfo,
        LawFirmAgents,
        ActivationInactivation,
        RequestActivationInactivation
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            lawFirm: null,
            reload: null,
        };
    },
    methods: {
        goToLawFirms() {
            this.$router.push({
                name: "law-firms",
            });
        },
        setLawFirm(lawFirm) {
            this.lawFirm = lawFirm;
        },
        setReload(reload) {
            this.reload = reload;
        }
    },
};
</script>