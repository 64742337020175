<template>
    <b-row class="mb-5">
        <b-col class="mt-4">
            <h6 class="page-title pb-2 text-uppercase">
                {{ $t("law-firm-agents.title") }}
            </h6>
            <b-row class="justify-content-end mb-3">
                <b-col xl="3" md="6">
                    <b-input-group class="mt-1">
                        <b-form-input
                            placeholder="Buscar"
                            class="rounded-0"
                            size="sm"
                            v-model="search"
                            @keydown.enter="filterTable"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button
                                class="main-btn"
                                size="sm"
                                @click="filterTable"
                            >
                                <b-icon-search></b-icon-search>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-card
                        no-header
                        no-body
                        class="rounded-0 shadow-sm border-0"
                    >
                        <b-table
                            stacked="md"
                            small
                            hover
                            select-mode="single"
                            selectable
                            show-empty
                            empty-text="No hay agentes residentes que mostrar"
                            :filter="filter"
                            :busy.sync="isBusy"
                            :fields="fields"
                            :items="retrieveData"
                            :current-page="currentPage"
                            :per-page="take"
                            @filtered="onFiltered"
                        >
                        </b-table>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-4 mb-5">
                <b-col>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="take"
                        size="sm"
                        class="my-0 justify-content-end"
                    >
                        <template #first-text>
                            <span
                                ><b-icon-chevron-double-left
                                    scale="1.3"
                                ></b-icon-chevron-double-left
                            ></span>
                        </template>
                        <template #prev-text>
                            <span
                                ><b-icon-chevron-left
                                    scale="1.3"
                                ></b-icon-chevron-left
                            ></span>
                        </template>
                        <template #next-text>
                            <span
                                ><b-icon-chevron-right
                                    scale="1.3"
                                ></b-icon-chevron-right
                            ></span>
                        </template>
                        <template #last-text>
                            <span
                                ><b-icon-chevron-double-right
                                    scale="1.3"
                                ></b-icon-chevron-double-right
                            ></span>
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";

export default {
    name: "law-firm-agents",
    props: {
        lawFirmId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            fields: [
                { key: "name", label: "Nombre" },
                { key: "identification", label: "Identificación" },
                { key: "identificationType", label: "Tipo de identificación" },
                { key: "email", label: "Email" },
                { key: "nationality", label: "Nacionalidad" },
                { key: "phone", label: "Teléfono" },
                { key: "birthDate", label: "Fecha de nacimiento" },
                { key: "residentAgentType", label: "Tipo de Rol" },
            ],
            search: "",
            filter: null,
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,
        };
    },
    methods: {
        filterTable() {
            this.filter = this.search;
        },
        onFiltered(filteredItems) {
            this.rows = filteredItems.length;
            this.currentPage = 1;
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    lawFirmId: this.lawFirmId,
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                };
                let response = await RepositoryFactory.residentAgent.getPaged(
                    payload
                );
                this.rows = response.data.total;
                let items = response.data.items;

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    item.birthDate = this.parseDate(item.birthDate);
                    item.residentAgentType = this.getResidentAgentString(
                        item.residentAgentType
                    );
                    item.identificationType = this.getIdentificationTypeString(
                        item.identificationType
                    );
                }

                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        parseDate(date) {
            if (date === null) {
                return "-";
            }
            return moment(date).format("DD/MM/YYYY");
        },
        getResidentAgentString(type) {
            switch (type) {
                case 0:
                    return "Abogado";
                case 1:
                    return "Asistente";
                case 2:
                    return "Interno";
                case 3:
                    return "Oficial de cumplimiento";
            }
            return "";
        },
        getIdentificationTypeString(type) {
            switch (type) {
                case 0:
                    return this.$t("general.cedula");
                case 1:
                    return this.$t("general.passport");
            }
            return "-";
        },
    },
};
</script>