<template>
  <b-row class="mb-5">
    <b-col class="mt-4">
      <h6 class="page-title pb-2 text-uppercase">Historial de solicitudes</h6>
      <b-row>
        <b-col>
          <b-card no-header no-body class="rounded-0 shadow-sm border-0">
            <b-table
              ref="rtable"
              stacked="md"
              small
              hover
              select-mode="single"
              selectable
              show-empty
              empty-text="No hay registros que mostrar"
              :filter="filter"
              :busy.sync="isBusy"
              :fields="fields"
              :items="retrieveData"
              :current-page="currentPage"
              :per-page="take"
              @filtered="onFiltered"
            >
              <template #cell(type)="data">
                {{ $t(`criteria.${getText(data.item.type, criteriaType)}`) }}
              </template>
              <template #cell(createdAt)="data">
                {{ formatDate(data.item.createdAt) }}
              </template>
              <template #cell(status)="data">
                {{
                  $t(
                    `request-activation-inactivation-status.${data.item.status}`
                  )
                }}
              </template>
              <template #cell(action)="data">
                <div>
                  <b-button
                    size="sm"
                    @click="seeCriteria(data.item)"
                    class="main-btn"
                  >
                    <b-icon
                      icon="eye"
                      v-b-tooltip.hover
                      title="Ver criterios"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4 mb-5">
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="take"
            size="sm"
            class="my-0 justify-content-end"
          >
            <template #first-text>
              <span
                ><b-icon-chevron-double-left
                  scale="1.3"
                ></b-icon-chevron-double-left
              ></span>
            </template>
            <template #prev-text>
              <span
                ><b-icon-chevron-left scale="1.3"></b-icon-chevron-left
              ></span>
            </template>
            <template #next-text>
              <span
                ><b-icon-chevron-right scale="1.3"></b-icon-chevron-right
              ></span>
            </template>
            <template #last-text>
              <span
                ><b-icon-chevron-double-right
                  scale="1.3"
                ></b-icon-chevron-double-right
              ></span>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-col>

    <!-- Modal -->
    <b-modal
      v-model="showModal"
      content-class="rounded-0"
      dialog-class="modal-lg"
      header-class="border-0"
      header-bg-variant="light"
      centered
      hide-footer
    >
      <template #modal-header>
        <div class="modal-custom-title">
          <h5 class="modal-title">Criterios</h5>
          <b-button
            size="sm"
            class="close-modal-button"
            variant="light"
            @click="closeModal"
          >
            <b-icon-x scale="1.5"></b-icon-x>
          </b-button>
        </div>
      </template>

      <div class="container" v-if="criteria">
        <b-row>
          <b-col md="4">
            <label class="details-title me-2">{{ $t("general.name") }}:</label>
            <span class="details-title-value">{{ criteria.lawFirm.name }}</span>
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">CUR:</label>
            <span class="details-title-value">{{ criteria.lawFirm.cur }}</span>
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">Tipo de solicitud:</label>
            <span
              class="details-title-value"
              :class="`criteria-type-${criteria.type}`"
              >{{ $t(`criteria-type.${criteria.type}`) }}</span
            >
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">Código:</label>
            <span class="details-title-value">{{ criteria.code }}</span>
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">Estado:</label>
            <span class="details-title-value">{{
              $t(`request-activation-inactivation-status.${criteria.status}`)
            }}</span>
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">Total de Criterios:</label>
            <span class="details-title-value">{{
              criteria.countCriterias
            }}</span>
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">Aprobados:</label>
            <span class="details-title-value">{{
              criteria.countApprovedCriterias
            }}</span>
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">Creado:</label>
            <span class="details-title-value">{{
              formatDate(criteria.createdAt)
            }}</span>
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">Por:</label>
            <span class="details-title-value">{{
              criteria.createSuperintendence
            }}</span>
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">Vigente:</label>
            <span class="details-title-value">{{ criteria.current }}</span>
          </b-col>
          <b-col md="4">
            <label class="details-title me-2">Observación:</label>
            <span class="details-title-value">{{ criteria.observation }}</span>
          </b-col>
        </b-row>

        <div class="mt-3">
          <table class="table" v-if="criteria.criterias.length">
            <thead>
              <tr>
                <th>Motivo</th>
                <th>Estado</th>
                <th>Cambio de estado</th>
                <th>Por</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="criteria in criteria.criterias"
                :key="criteria.criteriaId"
              >
                <td>{{ criteria.criteriaName }}</td>
                <td>
                  {{
                    $t(
                      `request-activation-inactivation-criteria-status.${criteria.status}`
                    )
                  }}
                </td>
                <td>
                  {{
                    criteria.modifiedStatusAt
                      ? formatDate(criteria.modifiedStatusAt)
                      : ""
                  }}
                </td>
                <td>{{ criteria.superintendence }}</td>
              </tr>
            </tbody>
          </table>

          <table class="table" v-if="criteria.justificationCriterias.length">
            <thead>
              <tr>
                <th>Motivo de inactivación</th>
                <th>Justificación para activación</th>
                <th>Estado</th>
                <th>Cambio de estado</th>
                <th>Por</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="criteria in criteria.justificationCriterias"
                :key="criteria.criteriaId"
              >
                <td>{{ criteria.criteriaName }}</td>
                <td>{{ criteria.justificationCriteriaName }}</td>
                <td>
                  {{
                    $t(
                      `request-activation-inactivation-criteria-status.${criteria.status}`
                    )
                  }}
                </td>
                <td>
                  {{
                    criteria.modifiedStatusAt
                      ? formatDate(criteria.modifiedStatusAt)
                      : ""
                  }}
                </td>
                <td>{{ criteria.superintendence }}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <b-button
              size="sm"
              variant="secondary"
              class="me-2"
              v-for="document in criteria.documents"
              :key="document.id"
              @click="getDocument(criteria.id, document.document)"
              >{{ document.document }}</b-button
            >
          </div>
        </div>

        <div class="mt-3">
          <ReviewRequestTimeline :data="criteria" />
        </div>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import ReviewRequestTimeline from "./ReviewRequestTimeline.vue";
import { criteriaType } from "@/config/criteria-type";
import { RepositoryFactory } from "@/services/api";
import moment from "moment";

export default {
  name: "request-activation-inactivation",
  components: {
    ReviewRequestTimeline,
  },
  props: {
    lawFirmId: {
      type: String,
      required: true,
    },
    reload: {
      type: String,
      required: false,
    },
  },
  watch: {
    reload: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$refs.rtable.refresh();
      }
    },
  },
  data() {
    return {
      criteriaType: criteriaType,
      fields: [
        { key: "type", label: "Tipo" },
        { key: "code", label: "Código", class: "text-center" },
        { key: "createdAt", label: "Fecha" },
        { key: "createSuperintendence", label: "Solicitante" },
        { key: "countCriterias", label: "Criterios", class: "text-center" },
        {
          key: "countApprovedCriterias",
          label: "Aprobados",
          class: "text-center",
        },
        { key: "status", label: "Estado", class: "text-center" },
        { key: "current", label: "Vigente", class: "text-center" },
        { key: "action", label: "Acción", class: "text-center" },
      ],
      search: "",
      filter: null,
      isBusy: false,
      currentPage: 1,
      take: 10,
      rows: 1,
      showModal: false,
      criteria: null,
      generating: false,
    };
  },
  methods: {
    filterTable() {
      this.filter = this.search;
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async retrieveData(context) {
      this.isBusy = true;
      try {
        let payload = {
          page: context.currentPage,
          take: 10,
          search: context.filter,
          lawFirmId: this.lawFirmId,
        };
        let response =
          await RepositoryFactory.lawFirm.getPagedRequestActivationInactivation(
            payload
          );
        this.rows = response.data.total;
        let items = response.data.items;
        return items;
      } catch (error) {
        window.console.log(error);
      }
      this.isBusy = false;
    },
    formatDate(date) {
      return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
    },
    getText(value, list) {
      const item = list.find((item) => item.value === value);
      return item ? item.text : null;
    },
    seeCriteria(item) {
      this.criteria = item;
      this.openModal();
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async getDocument(id, doc) {
      this.generating = true;
      try {
        let response = await RepositoryFactory.lawFirm.getDocument({
          id: id,
          document: doc,
        });

        if (response.data) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", doc);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      } catch (error) {
        window.console.log(error);
      } finally {
        this.generating = false;
      }
    },
  },
};
</script>

<style scoped>
.modal-custom-title {
  position: relative;
  width: 100%;
}

.close-modal-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.label-title {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 3px;
}

.table {
  color: #406892;
}
.table td,
.table th {
  border: 1px solid #406892;
}
</style>
