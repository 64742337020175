<template>
  <b-row class="mt-3">
    <b-col>
      <b-card border-variant="light" class="shadow-sm" header-class="border-0">
        <template #header>
          <h6 class="mb-0 details-title-value">
            {{ $t("law-firm-request-activation-inactivation.title") }}
          </h6>
        </template>
        <b-card-text>
          <b-row>
            <b-col xl="6" md="6">
              <LawFirmProperty :label="$t('general.name')" :value="lawFirm.name.toUpperCase()" />
              <LawFirmProperty label="CUR" :value="lawFirm.cur" />
              <LawFirmProperty :label="$t('general.cur-status')" :value="$t(`cur-status.${this.lawFirm.curStatus}`)" />

              <div class="mt-5 mb-3">
                <b-overlay :show="loading" rounded opacity="0.7" spinner-small spinner-variant="primary"
                  class="d-inline-block" v-if="lawFirm.curStatus === 1">
                  <b-button class="btn main-btn btn-secondary" @click="openActivation">
                    Solicitar activación
                  </b-button>
                </b-overlay>
              </div>
            </b-col>
            <b-col xl="6" md="6">
              <form @submit.prevent="save">
                <b-col>
                  <!--                   <strong v-if="lawFirm.curStatus === 0"
                    >Solicitud de Inactivación</strong
                  >
                  <strong v-else>Solicitud de Activación</strong> -->
                  <strong>Solicitud de Inactivación</strong>
                </b-col>

                <b-row class="mt-3">
                  <b-col md="4">
                    <label class="details-title">Seleccione motivo</label>
                  </b-col>
                  <b-col md="8">
                    <div class="position-relative">
                      <b-button class="w-100 collapse-criteria" v-b-toggle.collapse-3>
                        <b-row align-h="between">
                          <b-col class="text-start">
                            Seleccione criterio(s)
                          </b-col>
                          <b-col class="text-end">
                            <b-icon-chevron-down />
                          </b-col>
                        </b-row>
                      </b-button>
                      <b-collapse invisible id="collapse-3" class="position-absolute" style="z-index: 1000">
                        <b-card>
                          <b-form-checkbox-group class="checkbox-container" v-model="selected">
                            <b-row>
                              <b-col md="12" v-for="item in criterias" :key="item.key">
                                <b-form-checkbox :value="item.id">
                                  <span class="checkbox-text">
                                    {{ item.criterion }}
                                  </span>
                                </b-form-checkbox>
                              </b-col>
                            </b-row>
                          </b-form-checkbox-group>
                        </b-card>
                      </b-collapse>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col md="4">
                    <label class="details-title">Adjuntar documento legal</label>
                  </b-col>
                  <b-col md="8">
                    <div class="file-input">
                      <label for="image" class="btn main-btn btn-secondary">
                        <b-icon-file-earmark-arrow-up class="me-1" />
                        Cargar archivos
                      </label>
                      <input id="image" type="file" accept="*" multiple @change="onInputChange" />
                    </div>

                    <div class="previews mt-1 mb-4">
                      <div v-for="(file, index) in selectedFiles" :key="index" class="preview">
                        <a :href="file.preview" target="_blank" class="me-2">{{
              file.name
            }}</a>
                        <b-icon-x-circle style="cursor: pointer" @click="removeFile(index)" variant="danger"
                          v-b-tooltip.hover title="Quitar" />
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="4">
                    <label class="details-title">Observaciones (Opcional)</label>
                  </b-col>
                  <b-col md="8">
                    <b-form-textarea v-model="observation" size="sm" rows="4" no-resize class="rounded-0" />
                  </b-col>
                </b-row>

                <b-row class="mt-3">
                  <b-col class="text-end">
                    <b-overlay :show="loading" rounded opacity="0.7" spinner-small spinner-variant="primary"
                      class="d-inline-block">
                      <b-button class="btn main-btn btn-secondary" type="submit">
                        <!--         <span v-if="lawFirm.curStatus === 0"
                          >Enviar solicitud de inactivación</span
                        >
                        <span v-else>Enviar solicitud de activación</span> -->
                        <span>Enviar solicitud de inactivación</span>
                      </b-button>
                    </b-overlay>
                  </b-col>
                </b-row>
              </form>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-col>

    <b-modal id="confirmation" content-class="rounded-0" dialog-class="long-modal" body-bg-variant="light"
      hide-header-close centered no-close-on-backdrop hide-footer hide-header>
      <div class="container">
        <b-row class="text-center p-3">
          <b-col>
            <p class="details-title">
              <strong>Esta a punto de enviar una solicitud de
                <u>{{ $t(`criteria-type.${type}`) }}</u> a un Agente
                Residente</strong>
            </p>
            <p class="details-title">
              Primero revisé todos los criterios e Información del agente
              residente. Si está de acuerdo, presione clic en aceptar.
            </p>
          </b-col>
        </b-row>

        <b-row class="text-center">
          <b-col>
            <label class="details-title">{{ $t("general.name") }}</label><br />
            <span class="details-title-value">{{ lawFirm.name }}</span>
          </b-col>
          <b-col>
            <label class="details-title">CUR</label><br />
            <span class="details-title-value">{{ lawFirm.cur }}</span>
          </b-col>
          <b-col>
            <label class="details-title">Tipo de solicitud</label><br />
            <span class="details-title-value" :class="`criteria-type-${type}`">{{ $t(`criteria-type.${type}`) }}</span>
          </b-col>
        </b-row>

        <b-row class="mt-4 m-2">
          <b-col md="12">
            <label class="details-title">Criterios</label><br />
            <div v-for="(criteria, c) in selected" :key="c">
              {{ criterias.find((x) => x.id === criteria).criterion }}
            </div>
          </b-col>
          <b-col md="12">
            <label class="details-title mt-2">
              Documentos adjuntos ({{ selectedFiles.length }})
            </label>
            <!--<br />
                         <div v-for="(file, f) in selectedFiles" :key="f">
              {{ file.name }}
            </div> -->
          </b-col>
          <b-col md="12" v-if="observation">
            <label class="details-title mt-2">Observaciones</label><br />
            {{ observation }}
          </b-col>
        </b-row>

        <b-row class="text-center mt-4">
          <b-col>
            <b-overlay :show="loading" rounded opacity="0.7" spinner-small spinner-variant="primary"
              class="d-inline-block">
              <b-button class="main-btn me-3" size="sm" @click="accept" :disabled="loading">Aceptar</b-button>
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="$bvModal.hide('confirmation')">Cancelar</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal id="activation" content-class="rounded-0" dialog-class="modal-lg" body-bg-variant="light" hide-header-close
      centered no-close-on-backdrop hide-footer hide-header>
      <div class="container">
        <b-row class="text-center p-3">
          <b-col>
            <p class="details-title">
              <strong>Esta a punto de enviar una solicitud de
                <u>{{ $t(`criteria-type.0`) }}</u> para un Agente
                Residente</strong>
            </p>
            <p class="details-title">
              Primero revisé todos los criterios e Información del agente
              residente. Si está de acuerdo, presione clic en aceptar.
            </p>
          </b-col>
        </b-row>

        <b-row class="text-center">
          <b-col>
            <label class="details-title">{{ $t("general.name") }}</label><br />
            <span class="details-title-value">{{ lawFirm.name }}</span>
          </b-col>
          <b-col>
            <label class="details-title">CUR</label><br />
            <span class="details-title-value">{{ lawFirm.cur }}</span>
          </b-col>
          <b-col>
            <label class="details-title">Tipo de solicitud</label><br />
            <span class="details-title-value" :class="`criteria-type-0`">
              {{ $t(`criteria-type.0`) }}
            </span>
          </b-col>
        </b-row>

        <div class="mt-3">
          <table class="table table-activation">
            <thead>
              <tr>
                <th>Criterio de inactivación</th>
                <th class="text-center">Aprobado</th>
                <th>Justificación de activación</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(criteria, index) in criteriaInactivations" :key="index">
                <td>{{ criteria.criteriaName }}</td>
                <td align="center">
                  <b-icon-check-square-fill v-if="criteria.status === 1" v-b-tooltip.hover :title="$t(
              `request-activation-inactivation-criteria-status.${criteria.status}`
            )
              " />
                </td>
                <td>
                  <select v-if="criteria.status === 1 &&
              criteria.justificationStatus !== 1
              " v-model="justification[index]" class="form-control rounded-0"
                    @change="changeJustification(criteria, justification[index])">
                    <option v-for="option in justificationCriterias" :key="option.id" :value="option.id">
                      {{ option.criterion }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <b-button size="sm" variant="secondary" class="me-2" v-for="document in criteriaInactivationDocuments"
              :key="document.id" @click="getDocument(document.id, document.document)">{{ document.document }}</b-button>
          </div>

          <b-row class="text-end">
            <b-col md="12">
              <label class="details-title mb-2">Adjuntar documento legal</label>
              <div class="file-input">
                <label for="imageactive" class="btn main-btn btn-secondary">
                  <b-icon-file-earmark-arrow-up class="me-1" />
                  Cargar archivos
                </label>
                <input id="imageactive" type="file" accept="*" multiple @change="onInputChangeActive" />
              </div>

              <div class="previews mt-1 mb-4">
                <div v-for="(file, index) in selectedFilesActive" :key="index" class="preview">
                  <a :href="file.preview" target="_blank" class="me-2">{{
              file.name
            }}</a>
                  <b-icon-x-circle style="cursor: pointer" @click="removeFileActive(index)" variant="danger"
                    v-b-tooltip.hover title="Quitar" />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-row class="text-center mt-3">
          <b-col>
            <b-overlay :show="loading" rounded opacity="0.7" spinner-small spinner-variant="primary"
              class="d-inline-block">
              <b-button class="main-btn me-3" size="sm" @click="acceptActivation" :disabled="loading">Aceptar</b-button>
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="closeActivationModal">Cancelar</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import moment from "moment";
import { criteriaType } from "@/config/criteria-type";
import { RepositoryFactory } from "@/services/api";
import { mapActions } from "vuex";
import LawFirmProperty from "../law-firm/LawFirmProperty.vue";

export default {
  name: "activation-inactivation",
  components: {
    LawFirmProperty,
  },
  props: {
    lawFirm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      criteriaType: criteriaType,
      loading: false,
      type: null,
      observation: "",
      criterias: [],
      selected: [],
      selectedFiles: [],
      selectedFilesActive: [],
      criteriaInactivations: [],
      criteriaInactivationDocuments: [],
      justification: [],
      justificationCriterias: [],
      selectedJustification: [],
    };
  },
  async created() {
    await this.initial();
    await this.getJustificationCriterias();
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    async initial() {
      //this.type = this.lawFirm.curStatus === 0 ? 1 : 0;
      this.type = 1;
      await this.setType();
    },
    reset() {
      this.criteriaType = criteriaType;
      this.loading = false;
      this.type = null;
      this.observation = "";
      this.criterias = [];
      this.selected = [];
      this.selectedFiles = [];
    },
    async setType() {
      this.selected = [];
      await this.getCriterias(this.type);
    },
    async getCriterias(type) {
      this.loading = true;
      try {
        let response = await RepositoryFactory.criteriaRepository.getAll({
          type: type,
        });
        this.criterias = response.data;
      } catch (error) {
        window.console.log(error);
      }
      this.loading = false;
    },
    async getJustificationCriterias() {
      this.loading = true;
      try {
        let response = await RepositoryFactory.criteriaRepository.getAll({
          type: 0,
        });
        this.justificationCriterias = response.data;
      } catch (error) {
        window.console.log(error);
      }
      this.loading = false;
    },
    onInputChange(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.selectedFiles.push(e.target.files[i]);
      }
      this.handleFileChange();
      RepositoryFactory.event.add({
        code: 2003,
        details: "file upload"
      });
    },
    handleFileChange() {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];
        file.preview = URL.createObjectURL(file);
      }
    },
    onInputChangeActive(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.selectedFilesActive.push(e.target.files[i]);
      }
      this.handleFileChangeActive();
      RepositoryFactory.event.add({
        code: 2003,
        details: "file upload"
      });
    },
    handleFileChangeActive() {
      for (let i = 0; i < this.selectedFilesActive.length; i++) {
        const file = this.selectedFilesActive[i];
        file.preview = URL.createObjectURL(file);
      }
    },
    removeFile(index) {
      URL.revokeObjectURL(this.selectedFiles[index].preview);
      this.selectedFiles.splice(index, 1);
    },
    removeFileActive(index) {
      URL.revokeObjectURL(this.selectedFilesActive[index].preview);
      this.selectedFilesActive.splice(index, 1);
    },
    save() {
      if (this.selected.length === 0) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Selecciona al menos un criterio.",
        });
        return;
      }

      if (this.selectedFiles.length === 0) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Adjunta al menos un archivo.",
        });
        return;
      }

      this.$bvModal.show("confirmation");
    },
    async accept() {
      try {
        this.loading = true;

        let formData = new FormData();
        formData.append("lawFirmId", this.lawFirm.id);
        formData.append("type", this.type);
        formData.append("observation", this.observation);

        for (const item of this.selected) {
          formData.append("criterias", item);
        }

        for (const file of this.selectedFiles) {
          formData.append("files", file);
        }

        let response =
          await RepositoryFactory.lawFirm.requestActivationInactivation(
            formData
          );
        this.$bvModal.hide("confirmation");
        this.openModal();
        if (response.data) {
          this.reset();
          this.setModalInfo({
            title: "Aviso",
            message: "Solicitud enviada.",
          });
          this.$emit("refresh-request-history", response.data);
          await this.initial();
        } else {
          this.setModalInfo({
            title: "Error",
            message: "Error al enviar solicitud.",
          });
        }
      } catch (error) {
        let message = "Error al consumir servicio.";

        if (error?.response?.status === 400 && error?.response?.data) {
          message = "";
          if (typeof error.response.data === "object") {
            error.response.data.forEach((item) => {
              let msg = this.$t(
                `validation-exception.error_code.${item.value}`
              );
              message += `<p>${msg}</p>`;
            });
          } else if (typeof error.response.data === "string") {
            message = this.$t(error.response.data);
          }
        }

        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: message,
        });
      } finally {
        this.loading = false;
      }
    },
    openActivation() {
      this.$bvModal.show("activation");
      this.getCriteriaInactives();
    },
    async getCriteriaInactives() {
      try {
        this.loading = true;
        let response = await RepositoryFactory.lawFirm.getCriteriaInactives({
          id: this.lawFirm.id,
        });
        if (response.data) {
          this.criteriaInactivations = response.data.criterias;
          this.criteriaInactivationDocuments = response.data.documents;
        }
      } catch (error) {
        window.console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getDocument(id, doc) {
      this.generating = true;
      try {
        let response = await RepositoryFactory.lawFirm.getDocumentById({
          id: id,
          document: doc,
        });

        if (response.data) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", doc);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      } catch (error) {
        window.console.log(error);
      } finally {
        this.generating = false;
      }
    },
    formatDate(date) {
      return moment.utc(date).local().format("DD/MM/YYYY");
    },
    closeActivationModal() {
      this.justification = [];
      this.selectedJustification = [];
      this.$bvModal.hide("activation");
    },
    async acceptActivation() {
      // var criteriaInactivations = this.criteriaInactivations.filter(
      //   (x) => x.status === 1 && x.justificationStatus !== 1
      // ).length;

      if (
        this.selectedJustification === 0 || this.selectedJustification.length === 0
        //|| this.selectedJustification.length !== criteriaInactivations
      ) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Por favor complete al menos una justificación.",
        });
        return;
      }

      if (this.selectedFilesActive.length === 0) {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Adjunta al menos un archivo.",
        });
        return;
      }

      try {
        this.loading = true;
        let formData = new FormData();
        formData.append("lawFirmId", this.lawFirm.id);
        formData.append("type", 0);

        for (const item of this.selectedJustification) {
          const itemAsJson = JSON.stringify(item);
          formData.append("criterias", itemAsJson);
        }

        for (const file of this.selectedFilesActive) {
          formData.append("files", file);
        }

        let response =
          await RepositoryFactory.lawFirm.requestActivationInactivationJustification(
            formData
          );
        this.openModal();
        if (response.data) {
          this.closeActivationModal();
          this.reset();
          this.setModalInfo({
            title: "Aviso",
            message: "Solicitud enviada.",
          });
          this.$emit("refresh-request-history", response.data);
          await this.initial();
        } else {
          this.setModalInfo({
            title: "Error",
            message: "Error al enviar solicitud.",
          });
        }
      } catch (error) {
        console.log(error);
        let message = "Error al consumir servicio.";

        if (error?.response?.status === 400 && error?.response?.data) {
          message = "";
          if (typeof error.response.data === "object") {
            error.response.data.forEach((item) => {
              let msg = this.$t(
                `validation-exception.error_code.${item.value}`
              );
              message += `<p>${msg}</p>`;
            });
          } else if (typeof error.response.data === "string") {
            message = this.$t(error.response.data);
          }
        }

        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: message,
        });
      } finally {
        this.loading = false;
      }
    },
    changeJustification(item, justification) {
      const index = this.selectedJustification.findIndex(
        (element) =>
          element.requestActivationInactivationCriteriasId === item.id
      );

      if (index !== -1) {
        this.selectedJustification[index].criteriaId = justification;
      } else {
        const payload = {
          requestActivationInactivationCriteriasId: item.id,
          criteriaId: justification,
        };
        this.selectedJustification.push(payload);
      }
    },
  },
};
</script>

<style lang="scss">
.collapse-criteria {
  background-color: white !important;
  color: black !important;
  border-color: #406892 !important;
}



.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 10;
  left: 0;
  right: 0;
}

.criteria-type-0 {
  text-transform: uppercase;
  color: green;
}

.criteria-type-1 {
  text-transform: uppercase;
  color: red;
}

.table-activation {
  color: #406892;
}

.table-activation td,
.table-activation th {
  border: 1px solid #406892;
}
</style>
