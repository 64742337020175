<template>
    <b-row class="mt-3">
        <b-col>
            <b-card border-variant="light" class="shadow-sm" header-class="border-0">
                <template #header>
                    <h6 class="mb-0 details-title-value">
                        {{ $t("law-firm-info.title") }}
                    </h6>
                </template>
                <b-card-text>
                    <b-row v-if="loading" class="justify-content-center my-2">
                        <b-spinner variant="primary"></b-spinner>
                    </b-row>
                    <b-row v-if="!loading">
                        <b-col xl="6" md="9">
                            <LawFirmProperty :label="$t('general.name')" :value="lawFirm.name.toUpperCase()" />
                            <LawFirmProperty :label="$t('general.creation-date')" :value="lawFirm.creationDate" />
                            <LawFirmProperty label="CUR" :value="lawFirm.cur" />
                            {{ lawFirm.curStatus }}
                            <LawFirmProperty :label="$t('general.cur-status')"
                                :value="$t(`cur-status.${lawFirm.curStatus}`)" />
                        </b-col>
                        <b-col>
                            <b-button size="sm" @click="openModalData">
                                <b-icon-pencil></b-icon-pencil>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-col>

        <b-modal ref="modalData" content-class="rounded-0" dialog-class="long-modal" header-class="border-0"
            header-bg-variant="light" title="Editar" hide-header-close centered no-close-on-backdrop hide-footer>
            <b-form-group label="Nombre" label-for="name-input">
                <b-form-input id="name-input" v-model="lawFirm.name" required></b-form-input>
            </b-form-group>

            <b-row class="text-center mt-3">
                <b-col>
                    <b-overlay :show="saving" rounded opacity="0.7" spinner-small spinner-variant="primary"
                        class="d-inline-block">
                        <b-button class="main-btn me-3" size="sm" type="submit" :disabled="saving"
                            @click="updateData">Aceptar</b-button>
                    </b-overlay>
                    <b-button size="sm" variant="secondary" @click="closeModalData">Cancelar</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </b-row>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import LawFirmProperty from "./LawFirmProperty.vue";

export default {
    name: "law-firm-info",
    components: {
        LawFirmProperty,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            saving: false,
            loading: false,
            lawFirm: {},
        };
    },
    async mounted() {
        await this.retrieveLawFirm();
    },
    computed: {
        ...mapGetters("application", ["allowSuperintendenceModifyRA"]),
        allowUpdate() {
            return this.allowSuperintendenceModifyRA;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async retrieveLawFirm() {
            this.loading = true;
            try {
                let response = await RepositoryFactory.lawFirm.get({
                    id: this.id,
                });
                let lawFirm = response.data;

                lawFirm.creationDate = this.parseAndConvertDate(
                    lawFirm.creationDate
                );

                this.lawFirm = lawFirm;
                this.$emit("law-firm-info", this.lawFirm);
            } catch (error) {
                window.console.log(error);
            }
            this.loading = false;
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        async updateData() {
            this.saving = true;
            try {
                let payload = {
                    id: this.id,
                    name: this.lawFirm.name,
                };
                await RepositoryFactory.lawFirm.updateFirm(payload);
                this.$refs.modalData.hide();
            } catch {
                this.setErrorInfo();
                this.openModal();
            } finally {
                this.saving = false;
            }
        },
        setErrorInfo() {
            this.setModalInfo({
                title: "Aviso",
                message: "Error al consumir servicio",
            });
        },
        openModalData() {
            this.$refs.modalData.show();
        },
        closeModalData() {
            this.$refs.modalData.hide();
        },
    },
};
</script>
